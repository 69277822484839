import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Select, MenuItem, TextField, Stack, CircularProgress, Checkbox, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import Layout from '../../components/layout/Layout';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const Products = () => {
  const [allProducts, setAllProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [pageSize, setPageSize] = useState(15);
  const [loading, setLoading] = useState(true);
  const [syncing, setSyncing] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [imageFilter, setImageFilter] = useState('all');
  const [selectedRows, setSelectedRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [imageFile, setImageFile] = useState(null);

  const fetchProducts = async () => {
    setLoading(true);
    setError(null);

    // Retrieve the token and user_id from localStorage
    const token = localStorage.getItem('token');
    const user_id = localStorage.getItem('user_id'); // Make sure to store user_id when the user logs in
    
    if (!token) {
      setError('Authentication failed. Please log in again.');
      setLoading(false);
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    const formData = new FormData();
    formData.append('search', '');
    formData.append('limit', 5000);
    formData.append('offset', 0);
    formData.append('user_id', 181); // Add user_id to the FormData

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formData,
    };

    // try {
    //   const response = await fetch('https://app.supersteelpowertools.com/api/admin/get_product', requestOptions);
    //   if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

    //   const result = await response.json();
    //   setAllProducts(result.data);
    //   setFilteredProducts(result.data);
    // } catch (error) {
    //   setError(error.message);
    // } finally {
    //   setLoading(false);
    // }
    try {
      const response = await fetch('https://app.supersteelpowertools.com/api/admin/get_product', requestOptions);
      
      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
    
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.includes("application/json")) {
        const result = await response.json();
        setAllProducts(result.data);
        setFilteredProducts(result.data);
      } else {
        const text = await response.text(); // Retrieve the response text for debugging
        console.error("Received non-JSON response:", text);
        throw new Error("Received non-JSON response");
      }
    } catch (error) {
      console.error("Error:", error.message);
      setError(error.message);
    }
    
    
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = allProducts.filter(
      (product) =>
        (product.product_name && product.product_name.toLowerCase().includes(query)) ||
        (product.SKU && product.SKU.toLowerCase().includes(query)) ||
        (product.product_code && product.product_code.toLowerCase().includes(query))
    );
    setFilteredProducts(filtered);
  };

  const syncProducts = async () => {
    setSyncing(true);
    setError(null);

    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };

    try {
      const response = await fetch('https://app.supersteelpowertools.com/api/fetch_products', requestOptions);
      const result = await response.json();

      if (response.ok && result.message === "Products imported successfully") {
        setSuccessMessage(result.message);

        setTimeout(() => {
          setSuccessMessage(null);
          fetchProducts();
        }, 3000);
      } else {
        throw new Error(result.message || 'Failed to sync products');
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setSyncing(false);
    }
  };

  const handleImageFilterChange = (e) => {
    const filterValue = e.target.value;
    setImageFilter(filterValue);

    let filtered;
      if (filterValue === 'haveImage') {
        filtered = allProducts.filter((product) => product.product_image && product.product_image.trim() !== '');
      } else if (filterValue === 'noImage') {
        filtered = allProducts.filter((product) => !product.product_image || product.product_image.trim() === '');
      } else {
        filtered = allProducts;
      }


    setFilteredProducts(filtered);
  };

  const handleOpenDialog = (product) => {
    setCurrentProduct(product);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setImageFile(null);
  };

  const handleImageUpload = async () => {
    if (!imageFile || !currentProduct) return;
  
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('product_code', currentProduct.product_code);
    formData.append('product_image', imageFile);
  
    try {
      const response = await fetch('https://app.supersteelpowertools.com/api/admin/upload_product', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`, // Add the Authorization header with the token
        },
        body: formData,
      });
      
      if (!response.ok) throw new Error('Failed to upload image');
      
      const result = await response.json();
      console.log(result);
      handleCloseDialog(); 
      fetchProducts(); 
    } catch (error) {
      console.error(error);
      alert('Image upload failed. Please try again.');
    }
  };
  

  const columns = [
    { field: 'product_code', headerName: 'Code', flex: 0.5, filterable: true, type: 'number', headerAlign: 'center', align: 'center' },
    { field: 'product_name', headerName: 'Name', flex: 1.5 },
    { field: 'category', headerName: 'Category', flex: 0.5 },
    {
      field: 'product_image',
      headerName: 'Image',
      flex: 1,
      filterable: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
          {params.value ? (
            <img src={`https://ace.dotcombusiness.in${params.value}`} alt="Product" style={{ width: '60px', height: '40px' }} />
          ) : (
            <Typography>No Image</Typography>
          )}
          <Button variant="outlined" size="small" onClick={() => handleOpenDialog(params.row)} sx={{ ml: 1 }}>
            Upload
          </Button>
        </Box>
      ),
    },
    { field: 'basic', headerName: 'Basic Price', flex: 0.5, filterable: true, type: 'number', headerAlign: 'center', align: 'center' },
    { field: 'gst', headerName: 'GST', flex: 0.5, filterable: true, type: 'number', headerAlign: 'center', align: 'center' },
  ];

  return (
    <Layout>
      <Box sx={{ p: 3 }}>

      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        sx={{ mb: 3, justifyContent: 'space-between' }}
      >
          <Button
            onClick={syncProducts}
            variant="contained"
            color="primary"
            startIcon={<RefreshIcon />}
            sx={{ height: 40, backgroundColor: '#e31e25', color: 'white' }}
          >
            Sync Products
          </Button>

          <Stack direction="row" spacing={2} alignItems="center">
            <Select
              value={imageFilter}
              onChange={handleImageFilterChange}
              variant="outlined"
              sx={{ minWidth: 180, backgroundColor: 'white', height: 40 }}
              displayEmpty
            >
              <MenuItem value="all">All Products</MenuItem>
              <MenuItem value="haveImage">Have Image</MenuItem>
              <MenuItem value="noImage">No Image</MenuItem>
            </Select>

            <TextField
              variant="outlined"
              placeholder="Search by product name..."
              value={searchQuery}
              onChange={handleSearch}
              InputProps={{
                startAdornment: <SearchIcon sx={{ mr: 1 }} />,
                sx: { height: 40 },
              }}
              autoComplete="off"
              sx={{ width: 300, backgroundColor: 'white' }}
            />
          </Stack>
      </Stack>

        {syncing ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            <CircularProgress />
          </Box>
        ) : error ? (
          <Typography variant="body2" color="error" sx={{ textAlign: 'center', mt: 3 }}>
            {error}
          </Typography>
        ) : successMessage ? (
          <Typography variant="body2" color="primary" sx={{ textAlign: 'center', mt: 3 }}>
            {successMessage}
          </Typography>
        ) : (
          <Box sx={{ height: { xs: '60vh', sm: '70vh', md: '65vh' }, width: '100%', overflow: 'auto', alignItems: 'center' }}>
            <DataGridPro
              rows={filteredProducts.map((product) => ({ id: product.product_code, ...product }))}
              columns={columns}
              pageSize={pageSize}
              rowsPerPageOptions={[15, 25, 50, 100]} // Adds the row selection option
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              pagination
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                  exportOptions: { csvOptions: { fileName: 'Product_List' } },
                },
              }}
              headerFilters
              checkboxSelection 
              disableSelectionOnClick
              onSelectionModelChange={(newSelection) => setSelectedRows(newSelection)}
              selectionModel={selectedRows}
              sx={{
                '& .MuiDataGrid-columnHeaders': { backgroundColor: '#e31e25', color: '#000' },
                '& .MuiDataGrid-cell': { color: '#333333' },
              }}
            />
          </Box>
        )}

        <Dialog open={open} onClose={handleCloseDialog}>
          <DialogTitle>Upload Product Image</DialogTitle>
          <DialogContent>
            <TextField
              label="Product Code"
              value={currentProduct?.product_code || ''}
              fullWidth
              margin="normal"
              InputProps={{ readOnly: true }}
            />
            <Typography color="error" sx={{ mt: 2 }}>
              Upload only .jpg Format
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
              <input
                type="file"
                accept=".jpg"
                onChange={(e) => setImageFile(e.target.files[0])}
                id="upload-button"
                style={{ display: 'none' }}
              />
              <label htmlFor="upload-button">
                <Button
                  variant="contained"
                  component="span"
                  startIcon={<CloudUploadIcon />}
                  sx={{ padding: '10px 20px', borderRadius: '5px', backgroundColor: "#e31e25", color: "#fff" }}
                >
                  Choose File
                </Button>
              </label>
              {imageFile && (
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Selected file: {imageFile.name}
                </Typography>
              )}
            </Box>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleCloseDialog} sx={{ color: '#e31e25' }}>Cancel</Button>
            <Button onClick={handleImageUpload} sx={{ color: '#e31e25' }}>Upload</Button>
          </DialogActions>
        </Dialog>

        {/* <Typography variant="body2" sx={{ mt: 2 }}>
          {selectedRows.length} row{selectedRows.length === 1 ? '' : 's'} selected
        </Typography> */}
      </Box>
    </Layout>
  );
};

export default Products;
