import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, TextField, Button, Select, MenuItem, CircularProgress, Container } from '@mui/material';
import Layout from '../../components/layout/Layout';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom'; // Import useNavigate at the top of your component

const UpdateOrder = () => {
  const { id } = useParams();
  const [orderData, setOrderData] = useState(null);
  const [userName, setUserName] = useState('N/A');
  const [orderItems, setOrderItems] = useState([]);
  const [newOrderItems, setNewOrderItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [products, setProducts] = useState([]);
  const [productLoaded, setProductLoaded] = useState(false);
  const navigate = useNavigate(); // Initialize the useNavigate hook

  // Get token from localStorage
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchOrderData = async () => {
      try {
        if (!token) throw new Error('Token is missing.');

        const response = await fetch(`https://app.supersteelpowertools.com/api/admin/return_order/${id}`, {
          method: 'GET',
          headers: { 'Authorization': `Bearer ${token}` },
        });

        if (!response.ok) throw new Error(`Error fetching data. Status: ${response.status}`);

        const data = await response.json();
        setOrderData(data.data);
        setOrderItems(
          data.data.order_items.map((item) => ({
            ...item,
            amount: calculateAmount(item.quantity, item.rate),
            remarks: item.remarks || '',
          }))
        );

        // Fetch user name after fetching order data
        fetchUserName(data.data.user_id);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchOrderData();
  }, [id]);

  const fetchUserName = async (userId) => {
    try {
      const response = await fetch('https://app.supersteelpowertools.com/api/admin/view_user', {
        method: 'GET',
        headers: { 'Authorization': `Bearer ${token}` },
      });

      if (!response.ok) throw new Error(`Error fetching user data. Status: ${response.status}`);

      const userData = await response.json();
      const user = userData.data.find((user) => user.id === userId);

      if (user) {
        setUserName(user.name);
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const fetchAllProducts = async (userId) => {
    try {
      const formData = new FormData();
      formData.append('search', '');
      formData.append('limit', 5000);
      formData.append('offset', 0);
      formData.append('user_id', userId);

      const response = await fetch('https://app.supersteelpowertools.com/api/admin/get_product', {
        method: 'POST',
        headers: { 'Authorization': `Bearer ${token}` },
        body: formData,
      });

      if (!response.ok) throw new Error(`Error fetching products. Status: ${response.status}`);

      const data = await response.json();
      setProducts(data.data);
      setProductLoaded(true);
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    if (orderData && orderData.user_id) {
      fetchAllProducts(orderData.user_id);
    }
  }, [orderData]);

  const handleAddNewRow = () => {
    setNewOrderItems([
      ...newOrderItems,
      { product_code: '', product_name: '', quantity: 1, rate: 0, amount: 0, remarks: '' },
    ]);
  };

  const handleDeleteOrderItem = (index) => {
    const updatedOrderItems = orderItems.filter((_, i) => i !== index);
    setOrderItems(updatedOrderItems);
  };

  const handleDeleteNewRow = (index) => {
    const updatedNewItems = newOrderItems.filter((_, i) => i !== index);
    setNewOrderItems(updatedNewItems);
  };

  const handleProductSelect = (index, productCode, type) => {
    const selectedProduct = products.find(
      (product) => product.product_code.toLowerCase() === productCode.toLowerCase()
    );

    if (selectedProduct) {
      const updatedItems = [...(type === 'order' ? orderItems : newOrderItems)];
      const orderType = (orderData?.type || '').toLowerCase();
      const rate = orderType === 'gst' ? selectedProduct.gst : selectedProduct.basic;
      const amount = calculateAmount(updatedItems[index].quantity, rate);

      updatedItems[index] = {
        ...updatedItems[index],
        product_code: selectedProduct.product_code,
        product_name: selectedProduct.product_name,
        rate: rate,
        amount: amount,
      };

      if (type === 'order') {
        setOrderItems(updatedItems);
      } else {
        setNewOrderItems(updatedItems);
      }
    }
  };

  const handleNewItemChange = (index, field, value, type) => {
    const updatedItems = [...(type === 'order' ? orderItems : newOrderItems)];
    updatedItems[index] = { ...updatedItems[index], [field]: value };

    if (field === 'quantity' || field === 'rate') {
      updatedItems[index].amount = calculateAmount(updatedItems[index].quantity, updatedItems[index].rate);
    }

    if (type === 'order') {
      setOrderItems(updatedItems);
    } else {
      setNewOrderItems(updatedItems);
    }
  };

  const calculateAmount = (quantity, rate) => quantity * rate;

  const calculateGrandTotal = () => {
    const total = [...orderItems, ...newOrderItems].reduce(
      (acc, item) => acc + parseFloat(item.amount || 0),
      0
    );
    return total.toFixed(2);
  };

  // const handleUpdateOrder = async () => {
  //   try {
  //     if (!token) throw new Error('Token is missing.');

  //     const updatedItems = [
  //       ...orderItems.map((item) => ({
  //         product_code: item.product_code,
  //         product_name: item.product_name,
  //         quantity: item.quantity,
  //         rate: item.rate,
  //         total: item.amount,
  //         remarks: item.remarks,
  //       })),
  //       ...newOrderItems.map((item) => ({
  //         product_code: item.product_code,
  //         product_name: item.product_name,
  //         quantity: item.quantity,
  //         rate: item.rate,
  //         total: item.amount,
  //         remarks: item.remarks,
  //       })),
  //     ];

  //     const updateData = {
  //       order_id: orderData?.order_id,
  //       invoice_no: orderData?.order_invoice_id,
  //       order_type: orderData?.type,
  //       order_date: orderData?.date,
  //       user_id: orderData?.user_id,
  //       amount: calculateGrandTotal(),
  //       items: updatedItems,
  //     };

  //     const formData = new FormData();
  //     formData.append('data', JSON.stringify(updateData));

  //     const response = await fetch(`https://app.supersteelpowertools.com/api/admin/update_order/${id}`, {
  //       method: 'POST',
  //       headers: { 'Authorization': `Bearer ${token}` },
  //       body: formData,
  //     });

  //     if (!response.ok) throw new Error(`Error updating order. Status: ${response.status}`);
  //     const result = await response.json();
  //     console.log('Order updated successfully:', result);
  //   } catch (error) {
  //     console.error('Error updating order:', error.message);
  //   }
  // };

  const handleUpdateOrder = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('Token is missing.');

      // Construct the payload with order details and items
      const updateData = {
        order_id: orderData?.order_id,
        invoice_no: orderData?.order_invoice_id,
        order_type: orderData?.type,
        user_id: orderData?.user_id,
        amount: calculateGrandTotal(),
        items: [
          ...orderItems.map(item => ({
            product_code: item.product_code,
            product_name: item.product_name,
            quantity: item.quantity,
            rate: item.rate,
            total: item.amount,
            remarks: item.remarks || '',
          })),
          ...newOrderItems.map(item => ({
            product_code: item.product_code,
            product_name: item.product_name,
            quantity: item.quantity,
            rate: item.rate,
            total: item.amount,
            remarks: item.remarks || '',
          })),
        ],
      };

      // API URL with the correct order ID
      const apiUrl = `https://app.supersteelpowertools.com/api/admin/update_order/${id}`;

      // Make the POST request to update the order
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updateData),
      });

      if (!response.ok) throw new Error(`Error updating order. Status: ${response.status}`);

      const result = await response.json();

      // Handle success
      if (result.message === "Order updated successfully!") {
        alert(result.message); // Show the response message in an alert
        setOrderData(result.order); // Update order data in the state
        setOrderItems(result.items); // Update order items in the state

        // Navigate to the order page
        navigate('/orders'); // Replace '/orders' with your desired route
      }
    } catch (error) {
      console.error("Error updating order:", error.message);
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">Error: {error}</Typography>;

  return (
    <Layout>
      <Container>
        <Box sx={{ p: 3 }}>
          <Box sx={{ p: 1, display:'flex',justifyContent:'space-between' }}>
            <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', }}>{userName}</Typography>
            {/* <Typography variant="h6" gutterBottom>Update Order</Typography> */}
          </Box>
            {/* <TextField
              label="User Name"
              value={userName}
              fullWidth
              InputProps={{ readOnly: true }}
              sx={{ mb: 2 }}
            /> */}
          

          <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
            <TextField label="Order ID" value={orderData?.order_id || ''} fullWidth InputProps={{ readOnly: true }} />
            <TextField label="Invoice ID" value={orderData?.order_invoice_id || ''} fullWidth InputProps={{ readOnly: true }} />
            <TextField label="Order Date" value={orderData?.order_date || ''} fullWidth InputProps={{ readOnly: true }} />
            <TextField label="Order Type" value={orderData?.type || ''} fullWidth InputProps={{ readOnly: true }} />
          </Box>

          <Typography variant="h6" sx={{ mb: 2 }}>Order Items</Typography>
          {orderItems.map((item, index) => (
            <Box key={index} display="flex" gap={2} alignItems="center" mb={2}>
              <Select
                value={item.product_code}
                onChange={(e) => handleProductSelect(index, e.target.value, 'order')}
                fullWidth
                displayEmpty
                sx={{ minWidth: 200 }}
              >
                <MenuItem value="">Select a product</MenuItem>
                {products.map((product) => (
                  <MenuItem key={product.product_code} value={product.product_code}>
                    {product.product_name}
                  </MenuItem>
                ))}
              </Select>
              <TextField label="Rate" value={item.rate} fullWidth InputProps={{ readOnly: true }} />
              <TextField label="Quantity" value={item.quantity} onChange={(e) => handleNewItemChange(index, 'quantity', e.target.value, 'order')} fullWidth />
              <TextField label="Product Code" value={item.product_code} fullWidth InputProps={{ readOnly: true }} />
              <TextField label="Amount" value={item.amount} fullWidth InputProps={{ readOnly: true }} />
              <TextField label="Remarks" value={item.remarks} onChange={(e) => handleNewItemChange(index, 'remarks', e.target.value, 'order')} fullWidth />
              <IconButton color="error" onClick={() => handleDeleteOrderItem(index)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
            <Button variant="contained" color="primary" onClick={handleAddNewRow} sx={{ mb: 2 }}>
              Add Product
            </Button>
          </Box>

          {newOrderItems.length > 0 && (
            <>
              <Typography variant="h6" sx={{ mt: 3, mb: 2 }}>New Order Items</Typography>
              {newOrderItems.map((item, index) => (
                <Box key={`new-${index}`} display="flex" gap={2} alignItems="center" mb={2}>
                  <Select
                    value={item.product_code}
                    onChange={(e) => handleProductSelect(index, e.target.value, 'new')}
                    fullWidth
                    displayEmpty
                    sx={{ minWidth: 200 }}
                  >
                    <MenuItem value="">Select a product</MenuItem>
                    {products.map((product) => (
                      <MenuItem key={product.product_code} value={product.product_code}>
                        {product.product_name}
                      </MenuItem>
                    ))}
                  </Select>
                  <TextField label="Rate" value={item.rate} fullWidth InputProps={{ readOnly: true }} />
                  <TextField label="Quantity" value={item.quantity} onChange={(e) => handleNewItemChange(index, 'quantity', e.target.value, 'new')} fullWidth />
                  <TextField label="Product Code" value={item.product_code} fullWidth InputProps={{ readOnly: true }} />
                  <TextField label="Amount" value={item.amount} fullWidth InputProps={{ readOnly: true }} />
                  <TextField label="Remarks" value={item.remarks} onChange={(e) => handleNewItemChange(index, 'remarks', e.target.value, 'new')} fullWidth />
                  <IconButton color="error" onClick={() => handleDeleteNewRow(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
            </>
          )}

          <Box sx={{ display: 'flex', alignItems: 'center', mt: 3, gap: 2 }}>
            <Typography variant="h6">Grand Total</Typography>
            <TextField value={calculateGrandTotal()} fullWidth InputProps={{ readOnly: true }} />
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
            <Button variant="contained" color="success" onClick={handleUpdateOrder}>
              Update Order
            </Button>
          </Box>
        </Box>
      </Container>
    </Layout>
  );
};

export default UpdateOrder;
