
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Button, TextField, CircularProgress, InputAdornment } from '@mui/material';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import SearchIcon from '@mui/icons-material/Search';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { parseISO, format } from 'date-fns';
import Layout from '../../components/layout/Layout';

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [selectedOrder, setSelectedOrder] = useState(null);

  const fetchOrders = async () => {
    setLoading(true);
    setError(null);

    const token = localStorage.getItem('token');
    if (!token) {
      setError("Token is missing. Please login again.");
      setLoading(false);
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };

    try {
      const response = await fetch('https://app.supersteelpowertools.com/api/admin/view_order', requestOptions);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      setOrders(result.data);
      setFilteredOrders(result.data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  useEffect(() => {
    const filteredData = orders.filter(order => {
      return (
        String(order.order_id || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
        String(order.user?.name || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
        String(order.user?.mobile || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
        String(order.status || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
        String(order.type || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
        String(order.order_date || '').includes(searchQuery) ||
        String(order.amount || '').includes(searchQuery)
      );
    });
    setFilteredOrders(filteredData);
  }, [searchQuery, orders]);

  const columns = [
    { field: 'order_id', headerName: 'Order ID', flex: 1, align: 'center', headerAlign: 'center' },
    {
      field: 'user_name',
      headerName: 'User Name',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <Typography
          variant="body2"
          sx={{
            maxWidth: '150px',
            whiteSpace: 'normal',
            overflowWrap: 'break-word',
          }}
        >
          {params.row.user?.name || ''}
        </Typography>
      ),
    },
    { field: 'user_mobile', headerName: 'Mobile', flex: 1, align: 'center', headerAlign: 'center', type: 'number', renderCell: (params) => params.row.user?.mobile || '' },
    {
      field: 'order_date',
      headerName: 'Order Date',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
    },
    { field: 'status', headerName: 'Status', flex: 1, align: 'center', headerAlign: 'center' },
    { field: 'type', headerName: 'Type', flex: 1, align: 'center', headerAlign: 'center' },
    { field: 'amount', headerName: 'Amount', flex: 1, align: 'center', headerAlign: 'center', type: 'number' },
    {
      field: 'order_invoice',
      headerName: 'PDF Invoice',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) =>
        params.row.order_invoice ? (
          <a href={params.row.order_invoice} target="_blank" rel="noopener noreferrer">
            <PictureAsPdfIcon color="secondary" fontSize="large" />
          </a>
        ) : (
          <PictureAsPdfIcon color="disabled" fontSize="large" />
        ),
    },
    {
        field: 'update_order',
        headerName: 'Update Order',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params) => (
            <Button
                variant="contained"
                color="primary"
                onClick={() => handleUpdateOrder(params.row.id)} // Pass `id` directly
                sx={{ backgroundColor: 'orange', color: 'white' }}
            >
                Update
            </Button>
        ),
      },    
    // {
    //   field: 'generate_invoice',
    //   headerName: 'Generate Invoice',
    //   flex: 1,
    //   align: 'center',
    //   headerAlign: 'center',
    //   renderCell: (params) => (
    //     <Button
    //       variant="contained"
    //       color="primary"
    //       onClick={() => handleGenerateInvoice(params.row.id)}
    //       sx={{ backgroundColor: '#e31e25', color: 'white' }}
    //     >
    //       Generate
    //     </Button>
    //   ),
    // },
  ];

  const handleGenerateInvoice = async (orderId) => {
    const token = localStorage.getItem('token');
    if (!token) {
      setError("Token is missing. Please login again.");
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };

    try {
      const response = await fetch(`https://app.supersteelpowertools.com/api/admin/return_order/${orderId}`, requestOptions);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();

      if (result.data) {
        navigate(`./genarate_invoice/${orderId}`, { state: { invoiceData: result.data } });
      } else {
        setError("Failed to fetch invoice data.");
      }
    } catch (error) {
      setError(error.message);
    }
  };
//   const handleUpdateOrder = async (id) => {
//     const token = localStorage.getItem('token');
//     if (!token) {
//         setError("Token is missing. Please login again.");
//         return;
//     }

//     const myHeaders = new Headers();
//     myHeaders.append('Authorization', `Bearer ${token}`);

//     const requestOptions = {
//         method: 'GET',
//         headers: myHeaders,
//     };

//     try {
//         const response = await fetch(`https://app.supersteelpowertools.com/api/admin/return_order/${id}`, requestOptions); // Use `id` here
//         if (!response.ok) {
//             throw new Error(`HTTP error! Status: ${response.status}`);
//         }

//         const result = await response.json();
//         if (result.data) {
//             // Navigate to the update order page with the order data
//             navigate(`./update_order/${id}`, { state: { orderData: result.data } });
//         } else {
//             setError("Failed to fetch order data.");
//         }
//     } catch (error) {
//         setError(error.message);
//     }
// };

const handleUpdateOrder = async (id) => {
  const token = localStorage.getItem('token');
  if (!token) {
    setError("Token is missing. Please login again.");
    return;
  }

  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
  };

  try {
    const response = await fetch(`https://app.supersteelpowertools.com/api/admin/return_order/${id}`, requestOptions);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const result = await response.json();
    if (result.data) {
      // Make sure to include the `user_name` in the state object
      navigate(`./update_order/${id}`, { state: { orderData: result.data, user_name: result.data.user?.name } });
    } else {
      setError("Failed to fetch order data.");
    }
  } catch (error) {
    setError(error.message);
  }
};

  return (
    <Layout>
      <Box sx={{ p: 3 }}>
        <Box sx={{ mb: 3, display: 'flex', justifyContent: 'flex-end' }}>
          <TextField
            variant="outlined"
            placeholder="Search orders..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
              sx: { height: 40 },
            }}
            sx={{ maxWidth: 300, backgroundColor: 'white' }}
          />
        </Box>

        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            <CircularProgress />
          </Box>
        ) : error ? (
          <Typography color="error" sx={{ textAlign: 'center', mt: 3 }}>
            {error}
          </Typography>
        ) : (
          <Box sx={{ height: { xs: '60vh', sm: '70vh', md: '65vh' }, width: '100%' }}>
            <DataGridPro
                rows={filteredOrders.map((order) => ({ id: order.id, ...order }))}
                columns={columns}
                pageSize={pageSize} // This ensures the current page size is used
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 25, 50, 100]} // Page size options
                checkboxSelection
                components={{ Toolbar: GridToolbar }}
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      avatar: false,
                      id: false,
                    },
                  },
                }}
                headerFilters
                sx={{
                  '& .MuiDataGrid-columnHeaders': { backgroundColor: '#e31e25', color: '#000' },
                  '& .MuiDataGrid-cell': { color: '#333333', textAlign: 'center' },
                  '& .MuiDataGrid-cellCheckbox': { justifyContent: 'center' },
                }}
              />
          </Box>
        )}
      </Box>
    </Layout>
  );
  
};

export default Orders;
